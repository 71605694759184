html,
body {
	height: 100%;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
	height: 100%;
	scroll-behavior: smooth;
}

* {
	scroll-behavior: smooth;
}

* {
	color-adjust: exact;
	-webkit-print-color-adjust: exact;
	print-color-adjust: exact;
}

div[id^="onlyOnPDF"] {
	display: none;
}

div[id^="hideForNonPDF"] {
	visibility: hidden;
}

.pg-viewer-wrapper {
	overflow: hidden !important;
}

.pdf-canvas > canvas {
	width: 100%;
}

@page {
	margin: 5mm;
}

@media print {
	body * {
		visibility: hidden;
	}
	#printable *, #printable-Punch *, #printable-Tq *, #printable-Css * {
		visibility: visible;
	}

	div[id^="exclude"],
	header[id^="exclude"] {
		display: none;
	}
	div[id^="onlyOnPDF"] {
		display: block;
	}

	#printable {
		position: absolute;
		zoom: 0.5;
		left: 1px;
		right: 1px;
		top: 1px;
		bottom: 1px;
	}

	#printable-Punch, #printable-Tq {
		position: absolute;
		zoom: 0.7;
		left: 1px;
		right: 1px;
		top: 1px;
		bottom: 1px;
		margin: 2%;
	}

	#printable-Css {
		position: absolute;
		zoom: 1.2;
		left: 1px;
		right: 1px;
		top: 1px;
		bottom: 1px;
		margin: 2%;
	}

	.MuiDialog-paper {
		position: unset !important;
	}

	legend{
		opacity: 0;
	}
}
