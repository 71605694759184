body,
.ac-richTextBlock span,
.ac-columnSet .ac-textBlock {
	font-size: 16px !important;
}
.minimizable-web-chat > button.maximize {
	background-color: #3399ff;
	border-radius: 50%;
	border: 2px solid #3399ff;
	bottom: 10px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
	color: White;
	font-size: 30px;
	height: 64px;
	outline: 0;
	padding: 15px;
	position: fixed;
	right: 10px;
	width: 64px;
}
.maximize .ms-Icon--MessageFill:before {
	content: "";
	background: url("../../../assets/chatbot/images/chat_icon.svg") no-repeat;
	display: inline-block;
	width: 60px;
	height: 60px;
}
.minimizable-web-chat > button.maximize:hover {
	box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
}

.minimizable-web-chat > button.maximize > .red-dot {
	color: #ef0000;
	font-size: 14px;
	position: absolute;
	right: 12px;
	top: 12px;
}
button.maximize span {
	position: absolute;
	top: 0;
	left: 0;
}
.minimizable-web-chat > button.maximize:focus:not(:active),
.minimizable-web-chat > button.maximize:hover:not(:active) {
	content: "";
	background: url("../../../assets/chatbot/images/chat_iconhover.svg") no-repeat;
	display: inline-block;
}

.minimizable-web-chat > button.maximize:active {
	background-color: White;
	color: #3399ff;
}

.css-eycyw2 > .main {
	border-top: 1px solid #d6d4d4 !important;
	min-height: 45px !important;
}

.minimizable-web-chat > .chat-box {
	background-color: rgba(255, 255, 255, 0.8);
	backdrop-filter: blur(10px);
	border-radius: 0px;
	border-top-left-radius: 7px;
	border-top-right-radius: 7px;
	/* box-shadow: 0 0 20px rgba(0, 0, 0, .2); */
	border: 2px solid #d6d4d4;
	bottom: 20px;
	display: flex;
	flex-direction: column;
	max-width: 400px;
	min-width: 320px;
	position: fixed;
	top: 100px;
	width: 30%;
	border-top: none;
	z-index: 101;
}

.minimizable-web-chat > .chat-box.hide {
	display: none;
}

.minimizable-web-chat > .chat-box.left {
	left: 20px;
}

.minimizable-web-chat > .chat-box.right {
	right: 20px;
}

.minimizable-web-chat > .chat-box > header {
	background-color: #3399ff;
	height: 50px;
	display: flex;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	padding: 8px 10px;
	margin-left: -2px;
	margin-right: -2px;
}

.minimizable-web-chat > .chat-box > header > .filler {
	flex: 0 10000 100%;
}

.minimizable-web-chat > .chat-box > header button,
.filler button {
	width: 35px;
	height: 35px;
	background-color: Transparent;
	border: 0;
	color: White;
	outline: 0;
}
.headeractions {
	display: flex;
	align-items: center;
}
div.chat {
	overflow: hidden;
}
.minimizable-web-chat > .chat-box > header > button:focus:not(:active),
.minimizable-web-chat > .chat-box > header > button:hover:not(:active) {
	color: rgba(255, 255, 255, 0.6);
}

.minimizable-web-chat > .chat-box > header > button:focus:active {
	color: rgba(255, 255, 255, 0.4);
}

.minimizable-web-chat > .chat-box > .react-web-chat {
	flex: 1;
}
.message .from-user > .webchat__bubble__content,
.attachment.bubble .webchat__bubble__content .ac-container.ac-adaptiveCard > .ac-container > .ac-textBlock p {
	background: #3399ff !important;
	border-color: aliceblue !important;
	border-style: solid !important;
	border-width: 1px !important;
	color: #fff !important;
	min-height: 26px;
	border-radius: 4px !important;
	margin-right: 10px;
	display: inline-block;
}
.message .from-user > .webchat__bubble__content {
	max-width: 290px;
	float: right;
}
.attachment.bubble .webchat__bubble__content .ac-container.ac-adaptiveCard > .ac-container > .ac-textBlock p {
	width: auto !important;
	padding: 5px 15px;
	min-height: auto;
	margin-right: 4px;
	max-width: 72%;
	text-align: left;
}
.from-user > .webchat__bubble__content p,
.from-user > .webchat__bubble__content p {
	padding: 5px 15px;
}
.from-user > .webchat__bubble__content::after,
.attachment.bubble .webchat__bubble__content .ac-container.ac-adaptiveCard > .ac-container > .ac-textBlock p::after {
	width: 0;
	height: 0;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	display: inline-block;
	content: "";
	border-left: 10px solid #3399ff;
	position: absolute;
	z-index: 2;
	right: 3px;
	top: 46%;
	transform: translateY(-46%);
}
/* .attachment.bubble .webchat__bubble__content .ac-container.ac-adaptiveCard > .ac-container > .ac-textBlock p::after{
    top: 30%;
  } */
:not(.from-user) > .webchat__bubble__content {
	border-color: transparent !important;
	color: #2d4650 !important;
	min-height: 20px !important;
}
.ac-container.ac-adaptiveCard {
	padding: 10px 5px !important;
}
.ac-container.ac-adaptiveCard:focus {
	outline: none !important;
	border: none !important;
}
div.css-gtdio3 ul li:first-child {
	margin-bottom: 0px;
}
div.css-gtdio3 ul li:nth-child(2) {
	margin-top: 10px;
}
div.css-gtdio3 ul li:first-child .webchat__bubble__content {
	background: #3399ff;
	padding: 1rem 3rem 0.5rem;
}
div.css-gtdio3 ul li:first-child .ac-horizontal-separator {
	height: 20px !important;
}
div.css-gtdio3 ul li:first-child .ac-adaptiveCard div.ac-textBlock {
	color: #fff !important;
	/* font-size: 28px !important; */
	line-height: 30px !important;
	font-weight: 300 !important;
}
div.css-gtdio3 ul li:first-child div.ac-textBlock:nth-child(2) {
	font-size: 20px !important;
	color: #fff !important;
	line-height: 22px !important;
	font-weight: 200 !important;
	max-width: 220px;
}
div.css-gtdio3 ul li:first-child .ac-pushButton {
	background-color: transparent;
	color: #fff;
	padding: 8px 1.5rem;
	max-width: max-content;
	margin: 0rem;
	border-radius: 4px;
	text-transform: uppercase;
	cursor: pointer;
	font-weight: normal;
	border: 1px solid #ffffff;
}
div.css-gtdio3 ul li:first-child > div {
	margin-left: 0px !important;
	margin-right: 0px !important;
}
.logosymbol span,
.icon-help,
.icon-chat,
.icon-settings,
.icon-close,
.icon-minimize,
.icon-mail,
.icon-expertHelp {
	content: "";
	display: inline-block;
	width: 35px;
	height: 20px;
}
.logosymbol span {
	background: url("../../../assets/chatbot/images/favicon.svg") no-repeat;
	height: 30px;
}
.icon-help {
	background: url("../../../assets/chatbot/images/help.svg") no-repeat;
}
.icon-mail {
	background: url("../../../assets/chatbot/images/mail_icon.svg") no-repeat;
}
.icon-expertHelp {
	background: url("../../../assets/chatbot/images/ic_superuser01.svg") no-repeat;
}
.icon-chat {
	background: url("../../../assets/chatbot/images/headchat.svg") no-repeat;
}
.icon-settings {
	background: url("../../../assets/chatbot/images/moresettings.svg") no-repeat;
}
.icon-close {
	background: url("../../../assets/chatbot/images/close_icon.svg") no-repeat;
}
.icon-minimize {
	background: url("../../../assets/chatbot/images/minimize_icon.svg") no-repeat;
}
button[title="Send"][type="button"] {
	background: url("../../../assets/chatbot/images/send_icon.svg") no-repeat;
	content: "";
	display: inline-block;
	width: 23px;
	background-position: center;
	margin-right: 1rem;
}
button[title="Send"][type="button"] svg {
	display: none;
}
.ac-actionSet button {
	width: max-content;
	margin: 0px auto;
	border-color: #3399ff;
	border-radius: 4px;
	padding: 10px 3rem;
	min-width: 140px;
	cursor: pointer;
}
.ac-input-container input[type="number"] {
	max-width: 50px;
	padding: 0 0 0 4px;
	margin-left: 12px;
}
.ac-columnSet {
	margin: 8px 0 !important;
}
/* send_icon */
/* For scrolbar  */
.chat-box::-webkit-scrollbar {
	width: 5px;
}
.chat-box::-webkit-scrollbar-track,
.chat-box::-webkit-scrollbar-track:hover,
.chat-box::-webkit-scrollbar-track:active {
	background: #ffffff;
}
.chat-box::-webkit-scrollbar-thumb,
.chat-box::-webkit-scrollbar-thumb:hover,
.chat-box::-webkit-scrollbar-thumb:active {
	border: 1.3px solid #ffffff;
	background: #3399ff;
}
.page-navigation {
	::-webkit-scrollbar-track,
	::-webkit-scrollbar-track:hover,
	::-webkit-scrollbar-track:active {
		background: transparent;
	}
	::-webkit-scrollbar-thumb,
	::-webkit-scrollbar-thumb:hover,
	::-webkit-scrollbar-thumb:active {
		border: 1.6px solid #ffffff;
	}
}
div.webchat__connectivityStatus,
div[role="status"] {
	position: absolute;
	top: 42%;
	left: 0;
	right: 0;
	margin: 0 auto;
	text-align: center;
}
div.webchat__connectivityStatus span,
div[role="status"] span {
	display: block;
}
div.webchat__connectivityStatus,
div[role="status"] div[aria-hidden="true"] {
	text-align: center !important;
	display: inline-block;
	width: 100%;
	font-size: 16px;
	font-weight: bold;
	margin: 0;
}
div.webchat__connectivityStatus svg,
div[role="status"] div[aria-hidden="true"] svg {
	display: block;
	margin: 0 auto 10px;
	fill: #ffcc00;
	width: 50px;
	height: 50px;
}
div.webchat__connectivityStatus div,
div[role="status"] div[aria-hidden="true"] > div {
	background: url("../../../assets/chatbot/images/connecting_icon.svg") no-repeat !important;
	width: auto;
	height: 55px;
	background-size: contain !important;
	margin: 0 auto 10px;
	background-position: center !important;
}
div[role="form"] .main > div:first-child {
	visibility: hidden;
	width: 15px;
}
.tooltip {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: auto;
	background-color: #555;
	color: #fff;
	text-align: center;
	border-radius: 2px;
	padding: 3px 0;
	position: absolute;
	z-index: 1;
	top: -30px;
	left: -20px;
	right: 0;
	margin-left: auto;
	opacity: 0;
	transition: opacity 0.3s;
	font-size: 10px;
	margin-right: auto;
	word-wrap: break-word;
	width: 85px;
	padding: 3px 5px;
}
.headeractions .tooltip .tooltiptext {
	left: -35px;
}
.tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}
.ac-richTextBlock {
	padding: 10px 12px !important;
	border: 1px solid #3399ff;
	width: 80%;
}
.ac-textBlock {
	white-space: normal !important;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
	position: absolute;
	left: -9999px;
}
[type="radio"]:checked + div + label,
[type="radio"]:not(:checked) + div + label {
	position: relative;
	padding-left: 28px;
	cursor: pointer;
	line-height: 20px;
	display: inline-block;
	color: #666;
	font-size: 16px !important;
	margin: 2px 0;
}
[type="radio"]:checked + div + label:before,
[type="radio"]:not(:checked) + div + label:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 13px;
	height: 13px;
	border: 1px solid #ddd;
	border-radius: 100%;
	background: #fff;
}
[type="radio"]:checked + div + label:after,
[type="radio"]:not(:checked) + div + label:after {
	content: "";
	width: 9px;
	height: 9px;
	background: #3399ff;
	position: absolute;
	top: 3px;
	left: 3px;
	border-radius: 100%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + div + label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}
[type="radio"]:checked + div + label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}
.ac-input div {
	position: relative;
}
.ac-container.ac-adaptiveCard .ac-container > .ac-container .ac-textBlock {
	border: 1px solid #3399ff;
	padding: 12px;
	max-width: 290px;
	width: 100%;
}
.from-user.attachment > .webchat__bubble__content p,
.from-user.attachment > .webchat__bubble__content p {
	padding: 0px !important;
}
.from-user.attachment > .webchat__bubble__content::after,
.from-user.attachment.bubble .webchat__bubble__content .ac-container.ac-adaptiveCard > .ac-container > .ac-textBlock p::after {
	border: none;
}
.css-fawxas.from-user.attachment > .webchat__bubble__content,
[data-css-fawxas].from-user.attachment > .webchat__bubble__content {
	border-style: none;
}
